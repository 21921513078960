.Header_Home{
    width: 375px;  /* Or whatever */
    height: 74px; /* Or whatever */
    margin: auto;  /* Magic! */
    order:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titulo_home{
    width: 375px;  /* Or whatever */
    height: 74px; /* Or whatever */
    left: 0px;
    order:1;
    display: flex;
    align-items: center;
    padding: 0px;
}
.Logo_home{
    width: 60px;
    height: 72px;
    margin: auto;
    background-image: url(copa2022_sub.png);
    order: 1;
    margin-left: 18px;
    margin-right: 0px;
}
  
  .h2_home {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    text-align: center;

    color: #FFFFFF;
    margin: auto;
    margin-left: 0px;


    order: 2;
  }