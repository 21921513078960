.Main_Home {
    display: flex;

    margin: auto;
    align-items: center;
    flex-direction: column;
  }
  
  
  
  .Body {
    order: 2;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }

  .Body_Button{
    width: 375px;
    height: 130px;

    order:2;
    margin: auto;
    display: flex;
    align-items: center;
  }



.Button1_Home{
  width: 279px;
  height: 88px;
  margin: auto;
  border: 2px solid #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  background-color: #262627;
}

.Button1_Home:Hover{
  background-color: #4a4a4d;
  cursor: pointer;
}

.bt_home_img{
  width: 66px;
  height: 66px;
  background-image: url("group.png");
  margin: auto;
}

.bt_home_img2{
  width: 66px;
  height: 66px;
  background-image: url("eliminatoria.png");
  margin: auto;
}

.bt_home_titulo{
  width: 192px;
  height: 66px;
  margin: auto;

}

.bt_home_desc{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
text-align: center;

color: #FFFFFF;
}