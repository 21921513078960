.Termo{
  color: white;
  padding: 10px;
}

.botao_aceitaTermo{

  margin: auto;  /* Magic! */
display: flex;
order: 2;
justify-content: center;
flex-direction: column;
width: 100%;
}