.Main {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
}

.Header_Login{
  width: 375px;  /* Or whatever */
  height: 333px; /* Or whatever */
  margin: auto;  /* Magic! */
  order:1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login{
  width: 375px; 
  height: 333px; /* Or whatever */
  margin: auto;  /* Magic! */
  order:2;
  display: flex;
  flex-direction: column;
}

.Infos{
  width: 300px; 
  order:1;
  height: 250px; /* Or whatever */
  margin: auto;  /* Magic! */
  display: flex;
  flex-direction: column;
}

.Logo_Login{
  width: 230px;
  height: 275px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(copa2022.png);
}


.mensagem_login{
  font-family: 'Inter';
font-style: normal;
text-align: center;
color: #fdfdfd;
}

h3{
  top: 0px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
text-align: center;

color: #FFFFFF;
padding-top: 0px;
}

.h2_login {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-align: center;

  color: #FFFFFF;
  margin-top: 0px;
  margin-bottom: 0px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
